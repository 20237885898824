import * as React from "react";
import { Formik } from "formik";

import * as styles from "./index.module.scss";
import ContactImage from "../../../images/contact.inline.svg";
import RoundingImage from "../../../images/rounding.inline.svg";
import INIT_FROM_VALUES from "../../../constants/ContactUs";

import SectionLayout from "../../../components/SectionLayout";
import Input from "./components/Input";

import ValidationSchema from "./form";
import ContactUSApi from "../../../services/ContactUs";
import ToasterService from "../../../services/Toaster";
import { useService } from "../../../hooks/useService";

import {
  NAME_MAX,
  EMAIL_MAX,
  THEME_MAX,
  MESSAGE_MAX,
} from "../../../constants/ContactUs/validation";
import BackgroundImage from "../../../components/BackgroundImage";
import BackgroundSvg from "../../../images/contact_bg_card@3x.png";
import BackgroundSvg768 from "../../../images/contact_bg_card@2x.png";
import BackgroundSvg320 from "../../../images/contact_bg_card.png";

const ContactUsSection = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const toastr = useService(ToasterService);

  const handleEdit = async (form, { resetForm }) => {
    try {
      setIsLoading(true);
      const { name, email, theme, message } = form;
      await ContactUSApi.postUserInfo({
        name: name.trim(),
        email,
        subject: theme,
        message,
      });
      resetForm({});
      toastr.success(
        "Your message has been sent. We will answer you as soon as possible."
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SectionLayout className={styles.sectionWrapper}>
      <div className={styles.textWrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>Contact Us</span>
          <ContactImage className={styles.contactImage} />
        </div>
        <span className={styles.text}>
          Have comments or questions? Get in touch with us and one of our
          customer representatives will get back to you as soon as possible.
        </span>
      </div>
      <div className={styles.formWrapper}>
        <BackgroundImage
          backgroundImageDesktop={BackgroundSvg}
          backgroundImageTablet={BackgroundSvg768}
          backgroundImageMobile={BackgroundSvg320}
          imageStyle={styles.bgImageForForm}
        >
          <div className={styles.form}>
            <Formik
              initialValues={INIT_FROM_VALUES}
              validationSchema={ValidationSchema}
              validateOnChange={false}
              onSubmit={handleEdit}
            >
              {({ values, errors, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Input
                    id="name"
                    label="Name"
                    name="name"
                    placeholder="Enter your name"
                    value={values.name}
                    error={errors.name}
                    onChange={handleChange}
                    max={NAME_MAX}
                    isRequired
                  />
                  <Input
                    id="email"
                    label="Email"
                    name="email"
                    placeholder="Enter your Email"
                    value={values.email}
                    error={errors.email}
                    onChange={handleChange}
                    max={EMAIL_MAX}
                    isRequired
                  />
                  <Input
                    id="theme"
                    label="Write subject"
                    name="theme"
                    placeholder="Enter your subject"
                    value={values.theme}
                    error={errors.theme}
                    onChange={handleChange}
                    max={THEME_MAX}
                    isRequired
                  />
                  <Input
                    id="message"
                    label="Message"
                    name="message"
                    placeholder="Write your message"
                    value={values.message}
                    error={errors.message}
                    onChange={handleChange}
                    max={MESSAGE_MAX}
                    isRequired
                    isTextarea={true}
                  />
                  <div className={styles.buttonWrapper}>
                    <button
                      type="submit"
                      className={styles.button}
                      disabled={isLoading}
                    >
                      Send message
                    </button>
                    <RoundingImage className={styles.roundingImage} />
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </BackgroundImage>
      </div>
    </SectionLayout>
  );
};

export default ContactUsSection;
