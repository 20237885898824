import * as yup from "yup";

import {
  NAME_MIN,
  NAME_MAX,
  EMAIL_MAX,
  THEME_MIN,
  THEME_MAX,
  MESSAGE_MIN,
  MESSAGE_MAX,
} from "../../../constants/ContactUs/validation";

const ValidationSchema = yup.object().shape({
  name: yup
    .string()
    .min(NAME_MIN, "The name must contain at least two letters")
    .max(NAME_MAX, "The name must contain no more than 50 letters")
    .trim()
    .matches(/^[a-zA-Z\s]*$/, "The name must contain only letters")
    .required("Name is required"),
  email: yup
    .string()
    .max(EMAIL_MAX, "The email must contain no more than 129 letters")
    .email("Email is invalid")
    .required("Email is required"),
  theme: yup
    .string()
    .min(THEME_MIN, "The theme must contain at least two letters")
    .max(THEME_MAX, "The theme must contain no more than 100 letters")
    .trim()
    .required("Theme is required"),
  message: yup
    .string()
    .min(MESSAGE_MIN, "The theme must contain at least two letters")
    .max(MESSAGE_MAX, "The theme must contain no more than 1000 letters")
    .trim()
    .required("Message is required"),
});

export default ValidationSchema;
