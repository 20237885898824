import React from "react";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

import SuccessIcon from "./images/bx-check.inline.svg";

toast.configure();

const options = {
  autoClose: 4000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: false,
};

class ToasterService {
  static $displayName = "ToasterService";

  success(message) {
    toast.success(
      <div>
        <SuccessIcon className="mr-2" />
        {message}
      </div>,
      options
    );
  }

  error(message) {
    toast.error(message);
  }
}

export default ToasterService;
