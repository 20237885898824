export const NAME_MIN = 2;
export const NAME_MAX = 50;

export const EMAIL_MAX = 129;

export const THEME_MIN = 2;
export const THEME_MAX = 100;

export const MESSAGE_MIN = 2;
export const MESSAGE_MAX = 1000;
