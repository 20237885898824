// extracted by mini-css-extract-plugin
export var bgImageForForm = "index-module--bg-image-for-form--jpaVU";
export var button = "index-module--button--w7MeP";
export var buttonWrapper = "index-module--button-wrapper--q6FDp";
export var contactImage = "index-module--contact-image--XHLhA";
export var form = "index-module--form--qhX+D";
export var formWrapper = "index-module--form-wrapper--lWlH3";
export var roundingImage = "index-module--rounding-image--DZ1Fc";
export var sectionWrapper = "index-module--section-wrapper--5XLx3";
export var text = "index-module--text--y+jSU";
export var textWrapper = "index-module--text-wrapper--xfbSd";
export var title = "index-module--title--YvKfl";
export var titleWrapper = "index-module--title-wrapper--4Ix2-";