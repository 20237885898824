import axios from "axios";

class AxiosApi {
  constructor() {
    this.api = axios.create({
      baseURL:
        process.env.GATSBY_API_BASE_URL || "https://api.stage.fearn.co/api/v1",
    });
  }

  async post(endpointName, data) {
    return this.api.post(endpointName, data);
  }
}

const API = new AxiosApi();

export default API;
