import * as React from "react";
import PropTypes from "prop-types";

import * as styles from "./index.module.scss";

const Input = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  error,
  isRequired = true,
  isTextarea = false,
  min,
  max,
}) => {
  return (
    <div className={styles.inputWrapper}>
      <span className={styles.label}>{isRequired ? `${label}*` : label}</span>
      {isTextarea ? (
        <textarea
          name={name}
          className={styles.textarea}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={max}
          minLength={min}
        />
      ) : (
        <input
          name={name}
          className={styles.input}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={max}
          minLength={min}
        />
      )}
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.any,
  isRequired: PropTypes.bool,
  isTextarea: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default Input;
